import React from "react";
import SubHeaderQuery from "theme/modules/User/SubHeader/SubHeaderQuery.gql";
import StoreViewSelector from "../StoreViewSelector";
import EnhanceSubHeader from "./EnhanceSubHeader";
import CmsBlock from "theme/modules/CmsBlock";
import {useMediaQuery} from 'react-responsive'
import Breakpoints from "theme/components/atoms/Breakpoints";

const SubHeader = (props) => {
  const block = (props.loading) ? null : props.cmsBlock.find((el) => el?.identifier === "subheader_menu");
  const isNotMobile = useMediaQuery({minWidth: Breakpoints.mobile})

  return (
    <div className="store-view__wrapper">
      {/* 
      TODO: Delete when we get confirmation that we no longer will use this block
      <div className="cms-block-subheader desktop-only">
        {block && (<CmsBlock cmsBlock={block} />)}
      </div> */} 
      <StoreViewSelector
        displayMode={isNotMobile ? "full" : "short"}
        openDirection={"right"}
        theme={"dark"}
      />
    </div>
  );
};

export default EnhanceSubHeader({ SubHeaderQuery })(SubHeader);

