import PropTypes from "prop-types";
import React, {useRef, useEffect, useCallback} from "react";
import { compose } from "recompose";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import { SubmitButton } from "theme/components/atoms/Button";
import BodyParagraph from "theme/components/atoms/Typography/Body/BodyParagraph";
import OneLineForm from "theme/components/organisms/Form/OneLineForm";
import EnhanceSubscribeNewsletter, {trackNewsletter} from "./EnhanceSubscribeNewsletter";
import NewsletterMessages, {
  ErrorMessages, SuccessMessages
} from "./NewsletterMessages";
import SubscribeEmailToNewsletterMutation from "./SubscribeEmailToNewsletterMutation.gql";
import EnhanceCapencyModal from "theme/modules/User/CheckExistingUserForm/EnhanceCapencyModal.js";
import ModalCapency from "theme/components/organisms/Modal/ModalCapency";
import { default as SmartEmail, useEmailServerValidation } from "theme/modules/SmartForms/Field/Email";
import { withShop } from "web/core/shop/ShopContext";
import { withRouter } from "react-router";
import withNewsletterTracking from "theme/modules/Newsletter/withNewsletterTracking";

const messages = defineMessages({
  placeholder: {
    id: "modules.Newsletter.Subscribe.placeholder",
    defaultMessage: "Your email",
  },
  emailLabel: {
    id: "modules.Newsletter.Subscribe.emailLabel",
    defaultMessage: "Email",
  },
  callToAction: {
    id: "modules.Newsletter.Subscribe.callToAction",
    defaultMessage: "Send",
  },
});

const Subscribe = ({
  intl,
  subscribeEmailToNewsletter,
  commandPending,
  statusMessage,
  idPrefix = "",
  capencyModalState,
  closeCapencyModal,
  showCapencyModalError,
  showCapencyModalWarn,
  setCapencyLoading,
  setCapencyModalIsOpen,
}) => {

   // Email validation by Capency.
   const onSubmit = useEmailServerValidation(
    "email",
    setCapencyLoading,
    subscribeEmailToNewsletter,
    {
      valid: (context) => {
        closeCapencyModal();
        context.submit(context.formModel);
      },
      disabled: () => {
        closeCapencyModal();
      },
      warn: (context) => {
        const {
          formModel: { userEmail },
          validationResult: { message}
        } = context;
        showCapencyModalWarn({
          message,
          value: userEmail,
          handleSubmitForm: () => {
            closeCapencyModal();
            context.submit(context.formModel);
          },
          elementId: idPrefix + "email_newsletter",
        });
      },
      error: (context) => {
        const {
          formModel: { userEmail },
          validationResult: { message}
        } = context;
        showCapencyModalError({
          message,
          value: userEmail,
          elementId: idPrefix + "email_newsletter",
        });
      }
    });


  return (
  <>
    <div className="subscribe">
        {
          capencyModalState.isOpen &&
          <ModalCapency
            {...capencyModalState}
            setIsOpen={setCapencyModalIsOpen}
          />
        }
        <BodyParagraph>
          <FormattedMessage
            id="modules.Newsletter.Subscribe.description"
            defaultMessage="To know everything about extraordinary news and not miss any new trend, collection or original project signed Kaporal. Plus, take advantage of free Express delivery on your next order."
          />
        </BodyParagraph>
        <OneLineForm
        onValidSubmit={onSubmit}
        input={
          <div className="form-item__autocomplete"> 
            <SmartEmail
              name="email"
              id={idPrefix + "email_newsletter"}
              required
              aria-label={intl.formatMessage(messages.emailLabel)}
              placeholder={intl.formatMessage(messages.placeholder)}
              autoComplete="email"
            />
        </div>
        }
        button={
          <SubmitButton
            appearance="primary"
            state={commandPending ? "pending" : undefined}
          >
            {intl.formatMessage(messages.callToAction)}
          </SubmitButton>
        }
      />
    </div>
    <NewsletterMessages statusMessage={statusMessage} />
  </>
  );
};

Subscribe.propTypes = {
  idPrefix: PropTypes.string,
};

export default compose(EnhanceCapencyModal(),
withRouter,
withShop(),
withNewsletterTracking(),
EnhanceSubscribeNewsletter({
  SubscribeEmailToNewsletterMutation,
  SuccessMessages,
  ErrorMessages,
}),
)(injectIntl(Subscribe));
