import React from "react";
import Wysiwyg from "theme/modules/WysiwygV2";
import useFullUrl from "web/core/shop/useFullUrl";

const Reinsurance = (props) => {
  const baseUrl = useFullUrl();

  return (
    <section className="reinsurance">
      {props.cmsBlock && <Wysiwyg content={props.cmsBlock.contentWysiwyg} />}
    </section>
  );
};

export default Reinsurance;
