import React from "react";
import { Link } from "react-router-dom";
import Logo from "theme/components/atoms/Logo";
import HeaderHelper from "./HeaderHelper";

const SimpleHeader = (props) => {
  return (
    <div id="header" className="header header__simple">
      <div className="container header__container">
        <div className="header__logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <HeaderHelper props={props} />
      </div>
    </div>
  );
};

export default SimpleHeader;
