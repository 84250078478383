import React from "react";
import Alert from "theme/components/molecules/Alert";
import { FormattedMessage } from "react-intl";

const SuccessMessages = {
  CONFIRMATION_SENT: () => (
    <FormattedMessage
      id="modules.Newsletter.Subscribe.confirmationSent"
      defaultMessage="The confirmation request has been sent."
    />
  ),
  SUBSCRIPTION_DONE: () => (
    <>
      <FormattedMessage
        id="modules.Newsletter.Subscribe.subscriptionDone"
        defaultMessage="Thank you for your subscription."
      />
      <p className={"secondMessage"}>
        <FormattedMessage
          id="modules.Newsletter.Subscribe.subscriptionDone.secondMessage"
          defaultMessage="Kaporal website team is delighted to share his news that you will receive shortly."
        />
      </p>
    </>
  ),
};
const ErrorMessages = {
  EMAIL_ALREADY_SUBSCRIBED: () => (
    <FormattedMessage
      id="modules.Newsletter.Subscribe.emailAlreadySubscribed"
      defaultMessage="This email address is already subscribed."
    />
  ),
  EMAIL_ALREADY_ASSIGNED: () => (
    <FormattedMessage
      id="modules.Newsletter.Subscribe.emailAlreadyAssigned"
      defaultMessage="This email address is already assigned to another user."
    />
  ),
  NO_GUEST_ALLOWED: () => (
    <FormattedMessage
      id="modules.Newsletter.Subscribe.noGuestAllowed"
      defaultMessage="Sorry, but the administrator denied subscription for guests. Please register."
    />
  ),
  INVALID_EMAIL: () => (
    <FormattedMessage
      id="modules.Newsletter.Subscribe.invalidEmail"
      defaultMessage="Please enter a valid email address."
    />
  ),
  UNKOWN: () => (
    <FormattedMessage
      id="modules.Newsletter.Subscribe.unknown"
      defaultMessage="Sorry, an error occured. Please contact us to resolve this issue."
    />
  ),
};

const NewsletterMessages = ({ statusMessage }) => {
  if (!statusMessage) {
    return null;
  }

  if (statusMessage.Message && statusMessage.hasError) {
    return (
      <Alert
        appearance={
          statusMessage.redColor ? "newsletter-red" : "newsletter-black"
        }
      >
        <statusMessage.Message />
      </Alert>
    );
  }

  if (statusMessage.Message && !statusMessage.hasError) {
    return (
      <Alert appearance={"newsletter-black"}>
        <statusMessage.Message />
      </Alert>
    );
  }
};

export { ErrorMessages, SuccessMessages };
export default NewsletterMessages;
