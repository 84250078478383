import { compose, withHandlers, withState } from "recompose";
import makeCommandDispatcherWithMessageFeedback from "web/core/apollo/makeCommandDispatcherWithMessageFeedback";
import config from "config/stores";
import trackEvent from "theme/modules/Analytics/trackEvent";
import { sha256 } from "js-sha256";

const EnhanceNewsletter = ({
  SubscribeEmailToNewsletterMutation,
  SuccessMessages,
  ErrorMessages,
}) =>
  compose(
    withState("statusMessage", "setStatusMessage", null),
    withHandlers({
      onSuccess: (props) => ({ successMessage }) => {
        props.setStatusMessage({
          hasError: false,
          Message: SuccessMessages[successMessage]
            ? SuccessMessages[successMessage]
            : SuccessMessages["SUBSCRIPTION_DONE"],
        });
      },
      onError: (props) => ({ errorMessage }) => {
        props.setStatusMessage({
          hasError: true,
          Message: ErrorMessages[errorMessage]
            ? ErrorMessages[errorMessage]
            : ErrorMessages["UNKOWN"],
          redColor: errorMessage === "UNKOWN" || errorMessage === "INVALID_EMAIL",
        });
      },
    }),
    makeCommandDispatcherWithMessageFeedback(
      {
        subscribeEmailToNewsletter: (props) => (data) => {
          return {
            options: {
              mutation: SubscribeEmailToNewsletterMutation,
              variables: data,
            },
            callback: ({ status }) => {
              if (status === "success") {
                let user = props.user;
                user.email = data.email
                trackNewsletter("Newsletter subscription", user, props.shop)
              }
          },
          };
        },
      },
      false
    )
  );

  export const trackNewsletter = (eventName, user, shop) => {
    const locale = config[shop.id].default_country_id;
    trackEvent(eventName, {
      loggued: !!user?.id,
      idCustomer: user?.id,
      firstnameCustomer: user?.firstname,
      idCustomerCrm: user?.customer_cylande_id,
      emailCustomer: user?.email && sha256(user.email),
      language: locale,
      country: locale,
    });
  }

export default EnhanceNewsletter;
