import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const Spacer = ({ type, size = "2", hide }) => {
  const Component = type === "border" ? "hr" : "div";
  return (
    <Component
      className={classNames("spacer", {
        [`spacer--${type}`]: type,
        [`spacer--size-${size}`]: size,
        [`spacer--hide`]: hide,
      })}
    />
  );
};

Spacer.propTypes = {
  type: PropTypes.oneOf(["default", "border", "border-margin"]),
  size: PropTypes.oneOf(["0", "1", "2", "4"]),
};

export default Spacer;
