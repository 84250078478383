import React from "react";
import { compose, lifecycle } from "recompose";
import { FormattedMessage } from "react-intl";
import { initAnalytics } from "web/core/analytics";

import withConsentCookies from "front-commerce/src/web/core/analytics/withConsentCookies";
import CookieModal from "theme/modules/Cookie/CookieModal";
import Button from "theme/components/atoms/Button";
import CookieNotice from "theme/modules/Cookie/CookieNotice";

const CookieLink = ({
  authorizeAll,
  denyAll,
  setAllAuthorizations,
  allCookiesAreConfigured,
  services,
  cookie,
  updateAuthorizationsCookie,
  finalizeConfiguration,
  hasConsent,
  showModal,
  modalIsVisible,
}) => {
  // Save configuration on request close.
  const onRequestClose = () => {
    showModal(false);
    finalizeConfiguration();
  };

  return (
    <div className="cookie-link">
      <Button appearance="link" onClick={() => showModal(true)}>
        <FormattedMessage
          id="modules.Cookie.CookieLink.link"
          defaultMessage="Privacy"
        />
      </Button>
      {services && !hasConsent && (
        <CookieNotice
          showModal={showModal}
          authorizeAll={authorizeAll}
          denyAll={denyAll}
        />
      )}
      <CookieModal
        isOpen={modalIsVisible}
        onRequestClose={onRequestClose}
        services={services}
        cookie={cookie}
        updateAuthorizations={updateAuthorizationsCookie}
        setAllAuthorizations={setAllAuthorizations}
        allCookiesAreConfigured={allCookiesAreConfigured}
        finalizeConfiguration={onRequestClose}
      />
    </div>
  );
};

export default compose(
  withConsentCookies(),
  lifecycle({
    componentDidMount() {
      initAnalytics(this.props.cookie);
    },
  })
)(CookieLink);