import React from "react";
import CookieLink from "theme/modules/Cookie/CookieLink";

const Legislation = (props) => (
  <div className="footer-legislation">
    <ul className="footer-legislation__list container">
      <li className="footer-legislation__item">
        <CookieLink />
      </li>
      <li className="footer-legislation__item">{props.copyright}</li>
    </ul>
  </div>
);

export default Legislation;
