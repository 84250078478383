import React from "react";
import EnhanceHeaderHelper from "./EnhanceHeaderHelper";
import CmsBlock from "theme/modules/CmsBlock";
import HeaderHelperQuery from "./HeaderHelperQuery.gql";

const HeaderHelper = (props) => {
  const block = props.loading
    ? null
    : props.cmsBlock.find((el) => el?.identifier === "checkout_header_help");

  return (
    <div className="header__right">
      <div className="header__helper__block desktop-only">
        <div className="cms-block-subheader">
          {block && <CmsBlock cmsBlock={block} />}
        </div>
      </div>
      <div className="header__helper__block only-mobile"/>
    </div>
  );
};

export default EnhanceHeaderHelper({ HeaderHelperQuery })(HeaderHelper);
