import { compose, withHandlers, withState, withProps } from "recompose";
import { withRouter } from "react-router";
import { setRefreshing } from "theme/modules/Router/useRefreshing";

const EnhanceAlternatesLinks = () =>
  compose(
    withRouter,
    withProps(({ location }) => ({
      pathName: location.pathname,
      seoEffect: location.state?.seoTimestamp,
      seoLinks: location.state?.seoLinks,
    })),
    withState("alternates", "setAlternates", null),
    withHandlers({
      setDefaultAlternatesLinks: ({ setAlternates }) => () => {
        setAlternates(null);
      },
      alternateNavigate: ({
        history,
        location,
        alternates,
        setStoreView
      }) => (storeview) => {
        // If no alternate URL if detected, simply switch stores (redirect to homepage)
        if (alternates === null) {
          setStoreView(storeview)
        } else {
          // If there's an alternate, redirects to it.
          window.location.href = alternates.find((alt) => alt.store_code === storeview).path;
          setRefreshing(history, location)(true);
        }
      }
    })
  );

export default EnhanceAlternatesLinks;