import withTrackOnMount from "theme/modules/Analytics/withTrackOnMount";
import { sha256 } from "js-sha256";
import config from "config/stores";

export default () =>
  withTrackOnMount({
    event: "Newsletter subscription viewed",
    isResolvedFromProps: (props) =>  !!props.shop,
    mapPropsToProperties: ({user, shop }) => trackingNewsletterData(user, shop),
    shouldUpdateEvent: (prevProps, nextProps) => true,
    shouldFireOnComponentUpdate: (prevProps, nextProps) =>
    prevProps.location?.pathname !== nextProps.location.pathname,
  });

  export const trackingNewsletterData = (user, shop) => {
    const locale = config[shop.id].default_country_id;
    return  {
      loggued: !!user?.id,
      idCustomer: user?.id,
      firstnameCustomer: user?.firstname,
      idCustomerCrm: user?.customer_cylande_id,
      emailCustomer: user?.email && sha256(user.email),
      language: locale,
      country: locale,
    };
  }