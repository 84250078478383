import { compose, withProps } from "recompose";
import { graphql } from "react-apollo";
import branchServerClient from "web/core/branchServerClient";

const EnhanceFooter = ({ UserQuery, FooterConFigQuery }) =>
  compose(
    graphql(FooterConFigQuery, {
      name: "footerConifg",
    }),
    branchServerClient(
      withProps({
        me: {
          loading: true,
        },
      }),
      graphql(UserQuery, {
        name: "me",
      })
    )
  );

export default EnhanceFooter;
