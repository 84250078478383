import React from "react";
import { FormattedMessage } from "react-intl";
import { H2 } from "theme/components/atoms/Typography/Heading";
import Subscribe from "./Subscribe";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";
import CmsBlock from "theme/modules/CmsBlock";
import NewsletterCmsQuery from "./NewsletterCmsQuery.gql";
import compose from "recompose/compose";
import EnhanceNewsletterCms from "./EnhanceNewsletterCms";

const Newsletter = (props) => {
 const block = props.loading ? null : props.cmsBlock.find((el) => el?.identifier === "newsletter_image");

  return (
  <section className="newsletter">
    <div className="newsletter__content inverted">
      <div className="newsletter__title">
        <span className="h2">
          <FormattedMessage
            id="modules.Newsletter.keepInTouch"
            defaultMessage="Subscribe to our {name}"
            values={{
              name: (
                <span>
                  <FormattedMessage
                    id="modules.Newsletter.Label"
                    defaultMessage="of line."
                  />
                </span>
              ),
            }}
          />
        </span>
      </div>
      <div className="newsletter__subscribe">
        <Subscribe user={props.user} />
      </div>
    </div>
    <div className="newsletter__image tablet-desktop-only">
    {block && (<CmsBlock cmsBlock={block} />)} 
    </div>
  </section>
  );
};

const NewsletterEnhanced = compose(
  EnhanceNewsletterCms({ NewsletterCmsQuery })
)(Newsletter);

export default withHideOnErrorBoundary(NewsletterEnhanced);
