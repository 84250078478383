import { compose } from "recompose";
import { graphql } from "react-apollo";
import withErrorFallback from "theme/modules/PageError/withErrorFallback";

const EnhanceSubHeader = ({ SubHeaderQuery }) =>
  compose(
    graphql(SubHeaderQuery, {
      props: ({ data }) => {
        return {
          cmsBlock: data.cmsBlock || [],
          loading:
            data.loading ||
            (!data.error && typeof data.cmsBlock === "undefined"),
          error: data.error,
        };
      },
    }),
    withErrorFallback({
      hasError: (props) => props.error,
    })
  );

export default EnhanceSubHeader;
