import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Form from "theme/components/atoms/Form/Form";

const OneLineForm = ({ appearance, input, button, lefButton, ...rest }) => {
  return (
    <Form {...rest}>
      <div
        className={classNames("one-line-form", {
          [`one-line-form--${appearance}`]: appearance,
        })}
      >
        {lefButton && (
          <div className="one-line-form__left-button">{lefButton}</div>
        )}
        <div className="one-line-form__input">{input}</div>
        <div className="one-line-form__button">{button}</div>
      </div>
    </Form>
  );
};

OneLineForm.propTypes = {
  appearance: PropTypes.oneOf(["default", "button-icon"]),
};

export default OneLineForm;
