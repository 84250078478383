import React from "react";
import Wysiwyg from "theme/modules/WysiwygV2";

const SocialMedia = ({ cmsBlock }) => {
  return (
    <section className="social-media">
      {cmsBlock && <Wysiwyg content={cmsBlock.contentWysiwyg} />}
    </section>
  );
};

export default SocialMedia;
