import React, {useEffect, useState} from "react";
import { defineMessages, useIntl } from "react-intl";
import { branch, compose } from "recompose";
import classnames from "classnames";
import Button from "theme/components/atoms/Button";
import Icon from "theme/components/atoms/Icon";
import AvailableStoreQuery from "./AvailableStoreQuery.gql";
import EnhanceStoreViewSelector from "./EnhanceStoreViewSelector";
import getShopName from "./getShopName";
import StoreViewUrlQuery from "./StoreViewUrlQuery.gql";
import EnhanceAlternatesLinks from "./EnhanceAlternatesLinks";

const languages = defineMessages({
  de: {
    id: "modules.User.StoreViewSelector.languages.de",
    defaultMessage: "German",
  },
  en: {
    id: "modules.User.StoreViewSelector.languages.en",
    defaultMessage: "English",
  },
  es: {
    id: "modules.User.StoreViewSelector.languages.es",
    defaultMessage: "Spanish",
  },
  fr: {
    id: "modules.User.StoreViewSelector.languages.fr",
    defaultMessage: "French",
  },
})


const StoreViewSelector = ({
  alternates,
  alternateNavigate,
  getSeoLink,
  pathName,
  seoEffect,
  seoLinks,
  setAlternates,
  setDefaultAlternatesLinks,
  displayMode = "short",
  openDirection = "down",
  theme,
  ...props
}) => {
  const intl = useIntl();
  const [storesOpen, setStoresOpen] = useState(false)
  const currentStore = props.data.availableStores.find(store => store === props.data.shop.id)
  const getShopNameUsingDisplayMode = (currentStore) => {
    const storeName = getShopName(currentStore);

    return displayMode === "full" ?
      (languages?.[storeName] &&
      intl.formatMessage(languages?.[storeName])) :
        storeName;
  }

  // Empty alternates when navigate.
  useEffect(() => {
    setDefaultAlternatesLinks();
  }, [pathName, setDefaultAlternatesLinks]);

  // Set alternates from history state when they are set.
  useEffect(() => {
    if (seoEffect) {
      setAlternates(seoLinks);
    }
  }, [seoEffect, seoLinks, setAlternates]);

  const toggleStoresList = () => {
    setStoresOpen(!storesOpen)
  }

  const openCloseChevron = {
    down : {
      close: 'chevron-down',
      open: 'chevron-up',
    },
    right : {
      close: 'chevron-right',
      open: 'chevron-left',
    },
  };

  return (
    <div className={classnames("store-view-selector", {
      [`store-view-selector--${theme}`]: theme,
      [`store-view-selector--${openDirection}`]: openDirection,
    })}>
      <Button
        appearance="link"
        onClick={toggleStoresList}
      >
        {getShopNameUsingDisplayMode(currentStore)}
        <Icon icon={
                openCloseChevron[openDirection][
                    storesOpen ? "open" : "close"
                  ]
              }
              title={storesOpen ? "open" : "close"}
        />
      </Button>

      {storesOpen &&
      <ul className="store-view-selector--available-stores">
        {props.data.availableStores.filter(store => store !== currentStore).map((storeview) => (
          <li key={storeview}>
            <Button
              appearance="link"
              onClick={() => { alternateNavigate(storeview) }}
              onDisableClick={() => {}}
            >
              {getShopNameUsingDisplayMode(storeview)}
            </Button>
          </li>
        ))}
      </ul>
      }
    </div>
  );
};

export default compose(
  EnhanceStoreViewSelector({ AvailableStoreQuery, StoreViewUrlQuery }),
  EnhanceAlternatesLinks(),
  branch(
    (props) => props.data.loading,
    () => () => null,
    (BaseComponent) => BaseComponent
  )
)(StoreViewSelector);
